/**
* Generated automatically at built-time (2025-01-02T07:00:07.090Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-weinkuehlschraenke",templateKey: "sites/86-14aae1b9-5622-42f2-bd07-a78ecf9b666a"};